<template>
  <div>
    <b-row align-h="center">
      <b-col lg="6">
        <div class="card">
          <div class="card-body">
            <h6 class="h5 mb-4">
              ADMIN <span class="text-primary">PANEL</span> | {{ $t('buttons.login') }}
            </h6>

            <div v-if="loginState === 'LOGIN_USER_PASSWORD'">
              <ValidationObserver
                ref="loginForm"
                v-slot="{ handleSubmit }"
              >

                <b-form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(onSubmit)"
                >
                  <ValidationProvider
                    v-slot="{ errors, passed }"
                    :name="$t('user.username')"
                    rules="required"
                  >
                    <div class="form-group">
                      <label class="form-control-label">
                        {{ $t('user.username')}}
                      </label>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <feather
                              type="user"
                              class="align-middle icon-dual"
                            ></feather>
                          </span>
                        </div>
                        <b-form-input
                          id="username"
                          v-model="loginForm.username"
                          :state="passed ? true : errors[0] ? false : null"
                          :aria-invalid="false"
                          autocomplete="on"
                        ></b-form-input>
                      </div>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors, passed }"
                    vid="password"
                    rules="required"
                  >
                    <div class="form-group mt-4">
                      <label class="form-control-label">
                        {{ $t('user.password')}}
                      </label>
                      <router-link
                        to="forget-password"
                        class="float-right text-muted text-unline-dashed ml-1"
                      >
                        {{ $t('pages.login.forgot_password')}}
                      </router-link>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <feather
                              type="lock"
                              class="align-middle icon-dual"
                            ></feather>
                          </span>
                        </div>
                        <b-form-input
                          id="password"
                          v-model="loginForm.password"
                          :type="isShowPassword?'text':'password'"
                          :state="passed ? true : errors[0] ? false : null"
                          :aria-invalid="false"
                          autocomplete="new-password"
                        ></b-form-input>
                        <div class="input-group-append">
                          <b-button @click="isShowPassword = !isShowPassword">
                            <i
                              v-if="isShowPassword"
                              class="uil uil-eye"
                            ></i>
                            <i
                              v-else
                              class="uil uil-eye-slash"
                            ></i>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </ValidationProvider>
                  <b-form-group
                    id="button-group"
                    class="mt-4 mb-1"
                  >
                    <b-overlay
                      :show="isLoading"
                      rounded
                      spinner-small
                      class="d-inline-block"
                    >
                      <b-button
                        type="submit"
                        variant="primary"
                        block
                      >
                        {{ $t('buttons.login') }}
                      </b-button>
                    </b-overlay>

                  </b-form-group>
                </b-form>
              </ValidationObserver>
            </div>
            <div v-else-if="loginState === 'REQUIRED_CREATE_PIN'">
              <h5 class="text-warning text-center">คุณยังไม่ได้สร้าง Pin กรุณาสร้าง Pin ก่อนเพื่อเข้าใช้งาน</h5>
              <verify-login
                :title="createNewPinTitle"
                @submit="onSubmitCreateNewPin"
              />
              <b-alert
                :show="!!createNewPinError"
                variant="danger"
              >{{  createNewPinError }}</b-alert>
            </div>
            <div v-else-if="loginState === 'LOGIN_STATE_0'">
              <verify-login
                title="กรุณาใส่ Pin"
                @submit="onSubmitLoginPin"
              />
              <b-alert
                :show="!!isPinLoggingInError"
                variant="danger"
              >{{  isPinLoggingInError }}</b-alert>
            </div>

          </div>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import verifyLogin from './verify-login.vue'
export default {
  components: {
    verifyLogin,
  },
  page: {
    title: 'Log in',
  },
  data() {
    return {
      isShowPassword: false,
      loginForm: {
        username: '',
        password: '',
      },
      createNewPinCount: 0,
      createPinForm: {
        first: '',
        second: '',
      },
      createNewPinError: '',
    }
  },
  computed: {
    ...mapState(['auth']),
    ...mapGetters(['brandName', 'brandLogo']),
    isLoading() {
      const { isLoggingIn } = this.auth
      return isLoggingIn
    },
    loginState() {
      return this.auth.loginState
    },
    createNewPinTitle() {
      return this.createNewPinCount === 0
        ? 'กรุณาใส่ Pin'
        : 'กรุณาใส่ Pin อีกครั้งหนึ่ง'
    },
    isPinLoggingInError() {
      return this.auth.isPinLoggingInError
    },
  },
  created() {
    // this.fetchBrands()
  },
  methods: {
    ...mapActions(['login', 'fetchBrands', 'createNewPin', 'pinLogin']),
    onSubmit() {
      this.login(this.loginForm)
    },
    onSubmitCreateNewPin(pin) {
      if (this.createNewPinCount === 0) {
        this.createPinForm.first = pin
      }
      if (this.createNewPinCount === 1) {
        this.createPinForm.second = pin
      }
      this.createNewPinCount += 1
      if (this.createNewPinCount === 1) {
        this.createNewPinError = ''
      }
      if (this.createNewPinCount === 2) {
        if (this.createPinForm.first === this.createPinForm.second) {
          this.createNewPin(this.createPinForm.first.trim())
        } else {
          this.createNewPinError = 'Pin ไม่ตรงกัน'
          this.createNewPinCount = 0
          this.createPinForm.first = ''
          this.createPinForm.second = ''
        }
      }
    },
    onSubmitLoginPin(pin) {
      this.pinLogin(pin)
    },
  },
}
</script>
